<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">{{ getCommonText('decoding') }}. {{ currCategory.name }}</p>
            </div>
            <div class="right-content">
                <template v-if="categoryMode">
                    <div class="filter-actions filter-actions-flex"
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)">
                        <c-budget-forms-copy-data 
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            :total="total"
                            @keyPress="keyPress"
                        />
                    </div>
                </template>
                <template v-else>
                    <b-button variant="light" @click="resetModal">
                        <i class="icon icon-keyboard icon-rotate-180"></i> {{ getCommonText('back_to_list') }}
                    </b-button>
                    <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                        <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                    </b-button>
                    <b-button
                        :disabled="!dataChanged || goodsAreSaving"
                        variant="success"
                        @click="prepareForSave">
                        {{ getCommonText('save') }}
                    </b-button>
                </template>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                        :title="getCommonText('del_selected')"
                        class="icon icon-close table-all-remove"
                        @click="deleteItem(`${selectAll ? getCommonText('all_cats') : getCommonText('curr_cat')}`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <b-form-checkbox 
                        v-if="!parentCodes.includes(data.item.code)"
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                        v-b-modal.goods-modal @click="setCurrCatName(data.item)" 
                        v-if="!parentCodes.includes(data.item.code)">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(name_ru)="data">
                    <div :class="{'parent-cat': !data.item.par_id, 'child-cat': data.item.par_id,}">
                        {{ data.item.name }}
                    </div>
                </template>
                <template #cell(unit)="data">
                    <div>
                        {{ data.item.unit }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <template v-if="!parentCodes.includes(data.item.code)">
                        <div class="text-right">{{ $n(data.value) }}</div>
                    </template>
                </template>
                <template #cell(cost)="data">
                    <div  v-if="!parentCodes.includes(data.item.code)" class="text-right">
                        {{ $n(data.value) }}
                    </div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right" :class="{'parent-cat': !data.item.par_id}">
                        {{ $n(data.value) }}
                    </div>
                </template>
                <template #cell(files)="data">
                    <span v-if="!parentCodes.includes(data.item.code)"
                        class="blue pointer underline"
                        @click="onAllFilesClick(data.item)"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute && !parentCodes.includes(data.item.code)" :disabled="deletingCat || data.item.total === 0" @click="deleteItem(`${getCommonText('curr_cat')}`, data.item, data.index, true)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="6">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-03149-template1
                    ref="formTemplate"
                    :formCode="form.code"
                    :initialGoodsData="initialGoodsData"
                    :goodsData="goodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></form-03149-template1>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_414',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, Form03149Template1, ModalAllFilesManagement, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-414',
                name_ru: 'Расчет расходов по закупке вычислительного и другого оборудования',
                name_kk: 'Есептеу және басқа да жабдықтар сатып алу жөнiндегi шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: false,
            openDisabled: false,
            catsSums: [],
            isLoad: false
        };
    },

    mounted() {
        this.progress = 15;
    },

    methods: {

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.currCategory = row;
            this.$bvModal.msgBoxConfirm(
                this.getCommonText('del_with_decodes', {msg: msg}),
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                         if (row) {
                            this.itemUpdate(row);
                        } else {
                            const existingRowsToDel = this.budgetForm.filter(item => item.id >= 0 && item.itemToDelete);
                            if (!existingRowsToDel.length) { 
                                this.selectAll = false;
                            } else {
                                await this.deleteSeveralCats(existingRowsToDel);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        prepareData(val) {
            this.$set(val, 'good_type', val.equipment);
            this.$set(val, 'price', val.cost);
        }, // заполнение полей при загрузке расшифровок

        fillDataForSave(item, row) {
            this.$set(item, 'equipment', this.currCategory.code);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'cost', parseFloat(row.price));
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        async itemUpdate(row) {
            await this.loadGoodsData(row);
            process.nextTick(() => {
                this.deleteGood(this.goodsData, true, true);
                for (const itm of this.catsSums) {
                    if (itm.equipment === row.code) {
                        this.$set(itm, 'sum_amount', 0);
                        this.$set(itm, 'avg_price', 0.0);
                        this.$set(itm, 'sum_total', 0.0);
                        this.$set(itm, 'all_files', '');
                        break;
                    }
                }
                this.setCatSums();
            });
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        async loadCategoryData() {
            await this.loadDatas();
            this.catsSums.splice(0);
            this.load = true;
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.catsSums = values;
                        this.setCatSums();
                    } else {
                        this.budgetForm.splice(0);
                        this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, `Error code: ${response.status}`);
                    }
            } catch (error) {
                this.budgetForm.splice(0);
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }
            this.load = false;
        }, // загрузка расшифровок

        async loadDatas() {
            const that = this;
            this.load = true;
            this.budgetForm.splice(0);
            for (const row of this.dict) {
                const item = {
                    id: 0,
                    id_dict: row.id,
                    par_id: row.par_id,
                    code: row.code,
                    name_ru: row.name_ru,
                    amount: 0,
                    cost: 0,
                    total: 0,
                    itemToDelete: false
                };
                Object.defineProperty(item, 'name', {
                    get: () => {
                        const lg = this.$i18n.locale === 'kk' ? 'kk' : 'ru';
                        return row[`name_${lg}`];
                    }
                });
                Object.defineProperty(item, 'unit', {
                    get: () => {
                        if (this.lng === 'kk') return row.unit_kk;
                        return row.unit;
                    }
                });
                this.budgetForm.push(item);
            }
            this.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/equipments/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, error.toString());
            }
        },

        setCatSums() {
            if (this.budgetForm.length) {
                this.budgetForm.forEach(cat => {
                    if (!this.parentCodes.includes(cat.code)) {
                        const currCat = this.catsSums.find(item => item.equipment === cat.code)
                        if (currCat) {
                            this.$set(cat, 'amount', currCat.sum_amount);
                            this.$set(cat, 'cost',  parseFloat(currCat.avg_price.toFixed(2)));
                            this.$set(cat, 'total', parseFloat((currCat.sum_total / 1000).toFixed(3)));
                            this.$set(cat, 'files', this.getUnicFilesArr(currCat));
                        } else {
                            this.$set(cat, 'amount', 0);
                            this.$set(cat, 'cost', 0);
                            this.$set(cat, 'total', 0);
                            this.$set(cat, 'files', []);
                        };
                    };
                });
                this.budgetForm.forEach(cat => {
                    if (this.parentCodes.includes(cat.code)) {
                        const currCats = this.budgetForm.filter(item => item.par_id === cat.id_dict);
                        if (currCats.length) {
                            const totalSum = currCats.reduce((prev, curr) => prev + curr.total, 0);
                            this.$set(cat, 'total', totalSum);
                        } else {
                            this.$set(cat, 'total', 0);
                        }
                    }
                })
            }

        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if (row.par_id === parent.id_dict) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

         calcTotalBeforeDel() {
            const newTotal = this.budgetForm.length 
                                ? this.budgetForm.reduce((acc, row) => {
                                        if (!row.itemToDelete && !this.parentCodes.includes(row.code)) {
                                            return acc + row.total
                                        } else {
                                            return acc
                                        }
                                    }, 0) 
                                : 0;
            return newTotal
        },

        calcGoodsTotalBeforeDel() {
            // при необходимости заменить в родителе
            return this.budgetForm.length ? this.budgetForm.reduce((acc, row) => !this.parentCodes.includes(row.code) ? acc + row.total : acc, 0) : 0;
        },
    },

    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.par_id !== null || row.code === "110") {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },

        parentCodes() {
            const codelist = [];
            this.budgetForm.forEach(item => {
                if (!item.par_id && item.code !== "110") codelist.push(item.code);
            })
            return codelist;
        },

        getFormText() {
            return this.setFormText('form_01_414.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {
                        key: 'amount',
                        label: this.getCommonText('count')
                    },
                    {
                        key: 'cost',
                        label: this.getFormText('avg_unit_cost')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_cost')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
.parent-cat {
    font-weight: bold;
}
.child-cat {
    margin-left: 1rem;
}
.table-plus {
    border: none;
}
</style>