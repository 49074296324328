import { render, staticRenderFns } from "./modal-files-management.vue?vue&type=template&id=1a0a237e&scoped=true&"
import script from "./modal-files-management.vue?vue&type=script&lang=js&"
export * from "./modal-files-management.vue?vue&type=script&lang=js&"
import style0 from "./modal-files-management.vue?vue&type=style&index=0&id=1a0a237e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0a237e",
  null
  
)

export default component.exports